import React from 'react';
import Footer from 'globals/components/V2/Footer';
import HeaderComponent from 'globals/components/V2/Header';
import styles from './scss/commerce.module.scss';
import heroBannerImg from '../../assets/images/Commerce/herpBnr.webp';
import autoEvaluationCoreImg from '../../assets/images/Commerce/autoEvaluationCore.webp';
import dataDrivenOpsImg from '../../assets/images/Commerce/dataDrivenOperations.webp';
import practiceOrientedLearningImg from '../../assets/images/Commerce/practiceOrientedLearning.webp';
import scientificEducationApproachImg from '../../assets/images/Commerce/scientificEducationApproach.webp';
import coreLearning1 from '../../assets/images/Commerce/coreLearning1.webp';
import coreLearning2 from '../../assets/images/Commerce/coreLearning2.webp';
import coreLearning3 from '../../assets/images/Commerce/coreLearning3.webp';
import coreLearning4 from '../../assets/images/Commerce/coreLearning4.webp';
import coreLearning5 from '../../assets/images/Commerce/coreLearning5.webp';
import coreLearning6 from '../../assets/images/Commerce/coreLearning6.webp';
import logo1 from '../../assets/images/Commerce/logo1.webp';
import logo2 from '../../assets/images/Commerce/logo2.webp';
import logo3 from '../../assets/images/Commerce/logo3.webp';
import logo4 from '../../assets/images/Commerce/logo4.webp';
import logo5 from '../../assets/images/Commerce/logo5.webp';
import logo6 from '../../assets/images/Commerce/logo6.webp';
import logo7 from '../../assets/images/Commerce/logo7.webp';
import logo8 from '../../assets/images/Commerce/logo8.webp';
import aeTitleBg from '../../assets/images/Commerce/aeTitleBg.webp';
import forStudents from '../../assets/images/Commerce/forStudents.webp';
import forEdInst from '../../assets/images/Commerce/forEdInst.webp';
import cloudSvg from '../../assets/images/Commerce/clouds-cloud-svgrepo-com 1.webp';
import subSvg from '../../assets/images/Commerce/subject-svgrepo-com 1.webp';
import teacherSvg from '../../assets/images/Commerce/teacher-svgrepo-com 1.webp';
import college1Svg from '../../assets/images/Commerce/college-diploma-svgrepo-com 1.webp';
import college2Svg from '../../assets/images/Commerce/college-svgrepo-com 1.webp';
import scaleSvg from '../../assets/images/Commerce/scale-svgrepo-com 1.webp';

const CommerceProgram = () => {
  const productDetailsData = [
    {
      id: 1,
      title: 'Auto Evaluation Core',
      description:
        'The backbone of the E-Box Commerce Program is its Auto Evaluation Core, a sophisticated system that:',
      contentPoints: [
        'Provides instant feedback on student performance.',
        'Automates evaluation of practical exercises and assignments.',
        'Monitors progress with data-driven insights to ensure targeted improvement.',
        'Enables a scientific approach to teaching and learning by identifying individual learning gaps.',
      ],
      image: autoEvaluationCoreImg,
    },
    {
      id: 2,
      title: 'Practice-Oriented Learning',
      description: 'With a strong focus on real-world application, the program ensures:',
      contentPoints: [
        'Hands-on practice with tools like Power BI, Tableau, and SAP.',
        'Engagement through simulations and case studies.',
        'Continuous skill enhancement through structured assignments and challenges.',
      ],
      image: practiceOrientedLearningImg,
    },
    {
      id: 3,
      title: 'Data-Driven Operations',
      description: 'Leverage E-Box\'s advanced analytics capabilities to:',
      contentPoints: [
        'Track learning patterns and adapt teaching strategies.',
        'Provide actionable insights for educators and institutions.',
        'Predict student success and offer personalized interventions.',
      ],
      image: dataDrivenOpsImg,
    },
    {
      id: 4,
      title: 'Scientific Education Approach',
      description: 'The program employs scientific methodologies for:',
      contentPoints: [
        'Curriculum design based on market trends and future demands.',
        'Customized learning paths tailored to individual needs.',
        'Continuous assessment and improvement cycles.',
      ],
      image: scientificEducationApproachImg,
    },
  ];

  const coreLearningModulesData = [
    {
      id: 1,
      title: 'Business and Management',
      points: [
        'Management Principles',
        'Business Communication',
        'Organizational Behavior',
      ],
      image: coreLearning1,
    },
    {
      id: 2,
      title: 'Accounting and Financial Analysis',
      points: [
        'Financial and Management Accounting',
        'ERP Systems: Tally ERP, SAP',
        'Corporate Finance and Investment',
      ],
      image: coreLearning2,
    },
    {
      id: 3,
      title: 'Marketing and Branding',
      points: [
        'Digital Marketing',
        'Market Research and Consumer Behavior',
        'Brand Management and Analytics',
      ],
      image: coreLearning3,
    },
    {
      id: 4,
      title: 'Data Analytics and Tools',
      points: [
        'Microsoft Excel (Advanced)',
        'Tableau and Power BI',
        'Python and R for Business Analytics',
      ],
      image: coreLearning4,
    },
    {
      id: 5,
      title: 'Entrepreneurship and Leadership',
      points: [
        'Business Model Development',
        'Startup Ecosystem and Funding',
        'Innovation and Design Thinking',
      ],
      image: coreLearning6,
    },
    {
      id: 6,
      title: 'Global Business Awareness',
      points: [
        'International Trade and Finance',
        'Cross-Cultural Management',
        'Supply Chain Optimization',
      ],
      image: coreLearning5,
    },
  ];

  return (
    <>
      <HeaderComponent />
      <div className={styles.heroBanner}>
        <img
          className={styles.heroBannerImage}
          src={heroBannerImg}
          alt="Commerce Banner"
        />
        <div className={styles.heroTxt}>
          <h1 className={styles.bannerHeaderText}>E-Box Commerce Program</h1>
          <h2 className={styles.bannerSubHeaderText}>
            Empowering Commerce Education with Technology and Precision
          </h2>
          <p className={styles.bannerDescription}>
            The E-Box Commerce Program is a revolutionary approach to commerce
            education, powered by E-Box's Auto Evaluation Core. This state-of-the-art
            technology enables a practice-oriented, data-driven, and scientific
            approach to learning, making it the most comprehensive and innovative
            solution for students and educational institutions alike.
          </p>
        </div>
      </div>
      <div className={styles.stripSection}>
        <p>
          Whether you are an individual learner or a higher education institution, the
          E-Box Commerce Program transforms traditional learning into an interactive and
          measurable experience, equipping students with the tools and skills required for
          a dynamic business landscape.
        </p>
      </div>
      <div className={styles.programHighlights}>
        <h1>Why E-Box Commerce Program?</h1>
        <div className={styles.productDetailsContainer}>
          {productDetailsData.map((product) => (
            <div key={product.id} className={styles.productItem}>
              <img src={product.image} alt={product.title} className={styles.productImage} />
              <div>
                <h2>{product.title}</h2>
                <h3>{product.description}</h3>
                <ul>
                  {product.contentPoints.map((point, index) => (
                    <li key={`${product.id}-point-${index}`}>{point}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.coreLearningModulesOuter}>
        <h1>Core Learning Modules</h1>
        <div className={styles.coreLearningModules}>
          {coreLearningModulesData.map((module) => (
            <div key={module.id} className={styles.learningModuleCard}>
              <img src={module.image} alt={module.title} className={styles.moduleImage} />
              <h3>{module.title}</h3>
              <ul>
                {module.points.map((point, index) => (
                  <li key={`${module.id}-point-${index}`}>{point}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.integratedToolOuter}>
        <h1>Integrated Tools</h1>
        <h2>The program enables mastery of essential tools in:</h2>
        <div className={styles.integratedToolsContent}>
          <div>
            <h3>Accounting & Finance:</h3>
            <p>Tally ERP, QuickBooks, SAP ERP.</p>
          </div>
          <div>
            <h3>Collaboration:</h3>
            <p>Asana, Trello, Microsoft Project.</p>
          </div>
          <div>
            <h3>Digital Marketing:</h3>
            <p>Google Analytics, SEMrush, HubSpot.</p>
          </div>
          <div>
            <h3>Data Analytics:</h3>
            <p>Microsoft Excel, Power BI, Tableau, Python, R.</p>
          </div>
        </div>
      </div>
      <div className={styles.toolScrollSection}>
        <div className={styles.toolScrollContainer}>
          <img src={logo1} alt="Logo 2" className={styles.toolScroll} />
          <img src={logo2} alt="Logo 2" className={styles.toolScroll} />
          <img src={logo3} alt="Logo 3" className={styles.toolScroll} />
          <img src={logo4} alt="Logo 4" className={styles.toolScroll} />
          <img src={logo5} alt="Logo 5" className={styles.toolScroll} />
          <img src={logo6} alt="Logo 1" className={styles.toolScroll} />
          <img src={logo7} alt="Logo 2" className={styles.toolScroll} />
          <img src={logo8} alt="Logo 3" className={styles.toolScroll} />
          <img src={logo1} alt="Logo 2" className={styles.toolScroll} />
          <img src={logo2} alt="Logo 2" className={styles.toolScroll} />
          <img src={logo3} alt="Logo 3" className={styles.toolScroll} />
          <img src={logo4} alt="Logo 4" className={styles.toolScroll} />
          <img src={logo5} alt="Logo 5" className={styles.toolScroll} />
          <img src={logo6} alt="Logo 1" className={styles.toolScroll} />
          <img src={logo7} alt="Logo 2" className={styles.toolScroll} />
          <img src={logo8} alt="Logo 3" className={styles.toolScroll} />

        </div>
      </div>
      <div className={styles.autoEvalutionDivOuter}>
        <div className={styles.AutoEvaluationTitle}>
          <img src={aeTitleBg} alt="" className={styles.aeTitleBg} />
          <div className={styles.AutoEvaluationTitleTxt}>
            <h1>Auto Evaluation</h1>
            <h1> Core Features</h1>
          </div>
        </div>

        <div className={styles.autoEvaluationContent}>
          <div className={styles.autoEvaluationContent1}>
            <h1>1</h1>
            <div className={styles.aeContentRight}>
              <h2>Skill Assessment</h2>
              <p>Comprehensive evaluation of tool usage and theoretical application.</p>
            </div>
          </div>
          <div className={styles.autoEvaluationContent2}>
            <h1>2</h1>
            <div className={styles.aeContentRight}>
              <h2>Customizable Metrics</h2>
              <p>Measure performance against predefined benchmarks.</p>
            </div>
          </div>
          <div className={styles.autoEvaluationContent3}>
            <h1>3</h1>
            <div className={styles.aeContentRight}>
              <h2>Interactive Problem Solving</h2>
              <p>Real-time feedback on exercises.</p>
            </div>
          </div>

        </div>

      </div>
      <div className={styles.forWhom}>
        <div className={styles.forWhomInner}>
          <h2>For Students: Transforming Learning</h2>
          <img src={forStudents} alt="" />
          <div className={styles.forWhomTxt} />
          <h3>Benefits for Students</h3>
          <ul>
            <li>Hands-on practice through practice-oriented modules.</li>
            <li>Industry-aligned curriculum with cutting-edge tools.</li>
            <li>Personalized learning paths powered by data analytics.</li>
            <li>Globally recognized certifications to enhance employability.</li>
          </ul>
          <h3>Learning Outcomes</h3>
          <ul>
            <li>Mastery of essential commerce tools and techniques.</li>
            <li>Proficiency in decision-making through analytics and business intelligence.</li>
            <li>Readiness to meet real-world challenges with confidence.</li>
          </ul>
        </div>
        <div className={styles.forWhomInner}>
          <h2>For Educational Institutions: Redefining Delivery</h2>
          <img src={forEdInst} alt="" />
          <div className={styles.forWhomTxt} />
          <h3>Empowering Institutions</h3>
          <ul>
            <li>Deliver a modernized curriculum that aligns with industry trends.</li>
            <li>Automate assessments and track student progress with data-driven insights.</li>
            <li>Train faculty on the latest tools and technologies for effective delivery.</li>
          </ul>
          <h3>Customizable Solutions</h3>
          <ul>
            <li>Modular delivery to suit institutional needs.</li>
            <li>Integration with existing academic programs.</li>
            <li>Faculty development workshops to enable seamless adoption.</li>
          </ul>
        </div>
      </div>

      <div className={styles.aeWorks}>
        <h1>How the Auto Evaluation Core Works</h1>
        <div className={styles.aeWorksInner}>

          <div className={styles.aeWorksCont}>
            <h1>1</h1>
            <p>Real-Time Feedback Students receive immediate feedback on assignments and exercises, enhancing their learning efficiency.</p>
          </div>
          <div className={styles.vertLine} />
          <div className={styles.aeWorksCont}>
            <h1>2</h1>
            <p>Performance Analytics Detailed dashboards provide insights into student strengths and areas for improvement, enabling personalized learning experiences.</p>
          </div>
          <div className={styles.vertLine} />
          <div className={styles.aeWorksCont}>
            <h1>3</h1>
            <p>Continuous Monitoring Automated systems monitor progress and suggest interventions for improved outcomes.</p>
          </div>
          <div className={styles.vertLine} />
          <div className={styles.aeWorksCont}>
            <h1>4</h1>
            <p>Scientific Assessment Every assignment and project is evaluated based on predefined criteria, ensuring consistency and accuracy.</p>
          </div>
        </div>
      </div>
      <div className={styles.programDeliveryDiv}>
        <h1>Program Delivery</h1>
        <div className={styles.programDeliveryDivSub}>
          <h2>Seamless Learning Experience</h2>
          <div className={styles.subInner}>
            <div className={styles.subCont}>
              <img src={cloudSvg} alt="" />
              <h3>Cloud-Based Platform:</h3>
              <p>Access anywhere, anytime.</p>
            </div>
            <div className={styles.subCont}>
              <img src={subSvg} alt="" />
              <h3>Interactive Modules:</h3>
              <p>Multimedia content, quizzes, and simulations.</p>
            </div>
            <div className={styles.subCont}>
              <img src={teacherSvg} alt="" />
              <h3>Expert Mentorship:</h3>
              <p>Guidance from industry leaders and educators.</p>
            </div>
          </div>
        </div>
        <div className={styles.programDeliveryDivSub}>
          <h2>Flexible Integration</h2>
          <div className={styles.subInner}>
            <div className={styles.subCont}>
              <img src={college1Svg} alt="" />
              <p>Suitable for colleges, universities, and pre-university institutions.</p>
            </div>
            <div className={styles.subCont}>
              <img src={college2Svg} alt="" />
              <p>Customizable curriculum to meet institutional goals.</p>
            </div>
            <div className={styles.subCont}>
              <img src={scaleSvg} alt="" />
              <p>Scalable solutions for varied student groups.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.benefitDiv}>
        <div className={styles.benefitDivOuter}>
          <h1>Who Can Benefit from the Program?</h1>
          <div className={styles.benefitDivInner}>
            <div className={styles.benefitDivCont}>
              <h3>Students</h3>
              <p>Aspiring commerce professionals looking for a competitive edge.</p>
            </div>
            <div className={styles.benefitDivCont}>
              <h3>Colleges and Universities</h3>
              <p>Enhancing curriculum with technology-driven solutions.</p>
            </div>
            <div className={styles.benefitDivCont}>
              <h3>Pre-University Institutions (+1 & +2)</h3>
              <p>Building a strong foundation for higher education and careers.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  );
};

export default CommerceProgram;
